<template>
    <el-carousel trigger="click" height="340px" :autoplay="false">
        <el-carousel-item v-for="item in list" :key="item.id">
            <div class="box">
                <img :src="item.pic">
                <div class="title">
                    <h3 class="text-hide">{{ item.title }}</h3>
                </div>
            </div>
        </el-carousel-item>
    </el-carousel>
</template>

<script>
export default {
    name: "carousel",
    components: {
    },
    data () {
        return {
            list: [
                {
                    id: 1,
                    title: "关于开展社会应急无人机资源调研的函",
                    pic: 'https://picsum.photos/659/340?1'
                },
                {
                    id: 2,
                    title: "江西今年要建成市县级应急物资库61个",
                    pic: 'https://picsum.photos/659/340?2'
                }
            ]
        }
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
.el-carousel__item {
    .box {
        position: relative;
        width: 100%;
        height: 100%;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .title {
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            height: 62px;
            text-align: center;
            display: flex;
            align-items: flex-end;
            background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .6) 59.9%);

            h3 {
                color: #fff;
                font-size: 16px;
                margin: auto;
            }
        }
    }

}

/deep/.el-carousel__indicators--horizontal {
    bottom: 50px;
}
</style>