<template>
    <div class="item">
        <router-link :to="{ name: 'newsDetails', params: { id: item.id } }" class="text-hide">
            {{ item.title }}
        </router-link>
    </div>
</template>

<script>
export default {
    name: "categoryBlock",
    components: {
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {

        }
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
.item {
    a {
        display: block;
        font-size: 14px;
        color: #2E3033;
        text-decoration: none;
        padding-top: 15px;

        &::before {
            content: '·';
            margin-right: 10px;
        }

        &:hover {
            color: #EF312A;
        }
    }
}
</style>