import request from '../utils/request';

// 查询最新咨询分类资讯
export function getNewsCategoryBlock() {
  return request({
    url: '/getNewsCategoryBlock?apipost_id=75dce8f9b024',
    method: 'get',
  });
}

// 查询视频资讯
export function getVideoNews() {
  return request({
    url: '/getVideoNews?apipost_id=87de2f79b026',
    method: 'get',
  });
}
