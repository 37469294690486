<template>
    <div>
        <headers />

        <div class="container mb-30">

            <el-card class="box-card">
                <el-row :gutter="40">
                    <el-col :span="12">
                        <carousel />
                    </el-col>
                    <el-col :span="6">
                        <div class="hd-title">
                            <h3>分类名</h3>
                        </div>
                        <categoryBlock v-for="(item, index) in categoryBlockList" :key="index" :item="item" />
                    </el-col>
                    <el-col :span="6">
                        <div class="hd-title">
                            <h3>分类名</h3>
                        </div>
                        <categoryBlock v-for="(item, index) in categoryBlockList" :key="index" :item="item" />
                    </el-col>
                </el-row>

                <div class="pt-30">
                    <div class="hd-title border-bottom">
                        <h3>视频资讯</h3>
                    </div>
                    <div class="list">
                        <item v-for="(item, index) in videoList" :key="index" :item="item" />
                    </div>
                    <div class="text-center mt-30">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="[100, 200, 300, 400]" :page-size="100"
                            layout="total, sizes, prev, pager, next, jumper" :total="400">
                        </el-pagination>
                    </div>
                </div>
            </el-card>
        </div>
        <footers />
    </div>
</template>

<script>
import headers from "@/components/header.vue"
import footers from "@/components/footer.vue"
import carousel from "./components/carousel.vue"
import item from "./components/item.vue"
import categoryBlock from "./components/categoryBlock.vue"


import { getNewsCategoryBlock, getVideoNews } from "@/api/news"

export default {
    name: "news",
    components: {
        headers,
        footers,
        carousel,
        item,
        categoryBlock
    },
    data () {
        return {
            categoryBlockList: [],
            videoList: [],
            currentPage: 1,
        }
    },
    created () {
    },
    mounted () {
        this.searchCb()
        this.searchVn()
    },
    methods: {
        async searchCb () {
            const res = await getNewsCategoryBlock()
            this.categoryBlockList = res.data.data.list
        },
        async searchVn () {
            const res = await getVideoNews()
            this.videoList = res.data.data.list
        },
        handleSizeChange (val) {
            console.log(`每页 ${val} 条`)
        },
        handleCurrentChange (val) {
            console.log(`当前页: ${val}`)
        }
    }
}
</script>

<style lang="less" scoped>
.hd-title {
    &.border-bottom {
        padding-bottom: 15px;
        border-bottom: 2px solid #EF312A;
    }

    h3 {
        color: #ef312a;
    }
}

.list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
    margin-top: 30px;
}
</style>